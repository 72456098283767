@import '/app/src/theme/variables.module.less';

@font-size-base: 14px;
@input-placeholder-color: @gray-3;
@input-border-color: @gray-2;
@neutral-6: #8e979c;
@primary-blue-6: #2487e3;
@main-gray-3: #e7e9ea;
@gray-6: #737b7f;
@main-gray-7: #59666e;
:global {
  .ant-input {
    &.ant-input-sm {
      padding-top: 0;
      padding-bottom: 0;
      line-height: 1.375;
    }
    &.ant-input-lg {
      padding-top: 8px;
      padding-bottom: 8px;
      line-height: 22px;
    }
    &:hover,
    &:focus,
    .ant-input-focused {
      border-color: @primary-blue-6;
    }
    &:focus,
    .ant-input-focused {
      box-shadow: 0 0 0 2px rgb(0 104 215 / 20%);
    }
  }
  .ant-input-affix-wrapper .ant-input {
    &.ant-input-sm {
      padding-top: 0;
      padding-bottom: 0;
      line-height: 1.375;
    }
    &.ant-input-lg {
      padding-top: 4px;
      padding-bottom: 4px;
      line-height: 22px;
    }
    &:hover,
    &:focus,
    .ant-input-focused {
      border-color: @primary-blue-6;
    }
    &:focus,
    .ant-input-focused {
      box-shadow: 0 0 0 2px rgb(0 104 215 / 20%);
    }
  }
  .ant-input-group-wrapper.input-sbwm {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.input {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: @font-size-base;
  line-height: 22px;
  color: inherit;
  border-color: @main-gray-3;
  border-radius: 2px;
  transition:
    height 0s,
    padding 0s;
  &.disabled {
    color: @gray-6;
  }
  &.centered {
    text-align: center;
  }
  &.number {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      appearance: none;
    }
    &[type="number"] {
      appearance: textfield;
    }
  }
  &::placeholder {
    color: @main-gray-7;
  }
}
