@import '/app/src/theme/variables.module.less';

.popover-support {
  max-width: 416px;
  padding: 16px;
  .image {
    position: relative;
    img {
      border-radius: 50%;
    }
  }
  .title {
    margin-bottom: 4px;
  }
  .desc {
    margin-bottom: 16px;
  }
  .info {
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: @neutral-9;
    list-style: none;
    li:not(:last-child) {
      margin-bottom: 8px;
    }
    &-item {
      display: flex;
      align-items: center;
    }
  }
  .icon {
    margin-right: 8px;
  }
}
