@import '/app/src/theme/variables.module.less';

@select-border-color: @gray-2;
@border-radius-base: 0;
@gray-6: #737b7f;
@orange-8: #f47d2f;
@blue-1: #f9fafb;
@white: #ffffff;
@neutral-85: #1f3441;
@neutral-9: #132530;
@main-gray-2: #f3f4f4;
@main-gray-4: #d0d3d6;
@main-gray-7: #829bab;
@main-blue-gray-4: #d8e0e5;
.select {
  min-width: 120px;
  color: inherit;
  text-align: left;
  &.disabled {
    color: @gray-6;
  }
  .select [class*="__control"] {
    border-color: @select-border-color;
    border-radius: @border-radius-base;
  }
  [class*="__input-container"] {
    padding: 0;
    margin: 0;
  }
  [class*="__menu"] {
    z-index: 50;
  }
  [class*="-group"] {
    margin-bottom: 5px;
  }
  [class*="__group"] {
    [class*="-option"] {
      width: auto;
      margin-left: 20px;
    }
  }
  &.focused {
    [class*="__control"] {
      border-color: @orange-8;
    }
  }
  &.prefix {
    margin-right: 10px;
  }
  .prefix-control {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    margin: 0 0 0 10px;
    font-size: 11px;
    font-weight: 600;
    color: @blue-6;
    background: @blue-1;
    border-radius: 50%;
    &.active {
      color: @white;
      background: @orange-8;
    }
  }
  &.large {
    font-size: 16px;
  }
  &.default {
    font-size: 14px;
  }
  &.ghost {
    display: inline-block;
    min-width: auto;
    [class*="__control"] {
      background-color: transparent;
    }
    [class*="__indicator-separator"] {
      display: none;
    }
    [class*="__dropdown-indicator"] {
      padding: 2px;
    }
  }
  &.without-border {
    [class*="__control"] {
      border: none;
      box-shadow: none;
    }
  }
  &.header {
    [class*="__placeholder"] {
      color: @white;
    }
    [class*="__single-value"] {
      color: @white;
    }
    [class*="__indicator-separator"] {
      border-color: @white;
    }
    [class*="__indicator"] {
      color: @white;
    }
  }
  &.footer {
    [class*="__control"] {
      background-color: @neutral-9;
      border-color: @neutral-85;
    }
    [class*="__placeholder"] {
      color: @main-gray-7;
    }
    [class*="__single-value"] {
      color: @main-gray-7;
    }
    [class*="__indicator-separator"] {
      background-color: @neutral-85;
    }
    [class*="__indicator"] {
      color: @main-blue-gray-4;
    }
  }
  /* stylelint-disable-next-line selector-class-pattern */
  :global .select--vpoz__menu-list {
    scrollbar-width: thin;
    scrollbar-color: @white transparent;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: @white;
      border-radius: 100%;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 100%;
    }
    &::-webkit-scrollbar-thumb {
      background: @main-gray-2;
      border: 3px solid @main-gray-4;
      border-radius: 3px;
    }
  }
}
.select-item {
  display: flex;
  align-items: center;
  span {
    margin-right: 8px;
  }
}
