@import '/app/src/theme/variables.module.less';

.footer {
  &-logo {
    padding-bottom: 12px;
    text-align: left;
  }
  &-bottom {
    height: inherit;
  }
  &-top {
    height: inherit;
    margin-bottom: 24px;
  }
}
