@import '/app/src/theme/variables.module.less';

@white: #ffffff;
@black: #000000;
@primary-blue-8: #0068d7;
@neutral-11: #222e34;
:global {
  .ant-tooltip-inner {
    background-color: @primary-blue-8;
    border-radius: 4px;
  }
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: linear-gradient(
      to right bottom,
      rgb(0 104 215 / 90%),
      @primary-blue-8
    );
  }
}
.tooltip {
  position: relative;
  width: inherit;
  height: inherit;
  &-no-arrow {
    :global {
      .ant-tooltip-arrow {
        position: static;
        display: none;
        width: auto;
        height: auto;
        overflow: auto;
        transform: unset;
      }
    }
  }
}
.tooltip-type {
  &-primary {
    :global {
      .ant-tooltip-inner {
        background: @primary-blue-8;
      }
      .ant-tooltip-arrow > span {
        --antd-arrow-background-color: @primary-blue-8;
      }
    }
  }
  &-secondary {
    width: 100%;
    height: auto;
    margin: 0 0 0 1px;
    :global {
      .ant-tooltip-inner {
        padding: 16px;
        margin: -16px 0 0;
        font-size: 12px;
        border-radius: 0;
      }
    }
  }
}
.tooltip-color {
  &-default {
    color: @white;
    background: @neutral-11;
    :global {
      .ant-tooltip-inner {
        color: @white;
        background: @neutral-11;
      }
    }
  }
  &-secondary {
    :global {
      .ant-tooltip-inner {
        color: @black;
        background: @white;
      }
      .ant-tooltip-arrow > span {
        --antd-arrow-background-color: @white;
        &::before {
          background: @white;
        }
      }
    }
  }
}
.tooltip-gutter {
  padding: 5px 8px;
  &-small {
    :global {
      .ant-tooltip-inner {
        padding: 6px 8px;
      }
    }
  }
  &-middle {
    :global {
      .ant-tooltip-inner {
        padding: 8px 10px;
      }
    }
  }
  &-large {
    :global {
      .ant-tooltip-inner {
        padding: 10px 12px;
      }
    }
  }
}
