@import '/app/src/theme/variables.module.less';

.icon {
  display: inline-block;
  width: auto;
  height: auto;
  font-size: inherit;
  font-style: normal;
  line-height: 0;
  color: inherit;
  text-align: center;
  text-transform: none;
  vertical-align: middle;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  & > * {
    line-height: 1;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
  }
  &::before {
    display: none;
  }
  &.mini {
    font-size: 12px;
  }
  &.small {
    font-size: 16px;
  }
  &.middle {
    font-size: 24px;
  }
  &.large {
    font-size: 32px;
  }
  &.extra-large {
    font-size: 40px;
  }
  &.full-width {
    width: 100%;
    height: 100%;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &.rotate {
    animation: rotate 1s infinite ease-out;
  }
  &.mr-small {
    margin-right: 8px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
