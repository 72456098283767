@import '/app/src/theme/variables.module.less';

.light-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.light-list-item {
  min-width: 16px;
  min-height: 16px;
  &-loading {
    :global {
      .ant-skeleton-input {
        width: 40px;
        min-width: auto;
        height: 40px;
        line-height: 1;
      }
    }
  }
}
/* stylelint-disable no-descending-specificity */
.light-list-vertical {
  display: flex;
  flex-direction: column;
}
.light-list-horizontal {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.light-list-vertical .light-list-item {
  padding-top: 8px;
  &:first-child {
    padding-top: 0;
  }
}
.light-list-horizontal .light-list-item {
  margin-left: 10px;
  &:first-child {
    margin-left: 0;
  }
}
/* stylelint-enable no-descending-specificity */
.light-list-item-icon {
  height: inherit;
  &-actions {
    padding: 12px;
  }
}
