@import '/app/src/theme/variables.module.less';

@dark: #131c20;
.header {
  position: relative;
  z-index: 10;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 0;
  background: @dark;
  &.ghost {
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
  }
}
.header-inner {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  height: fit-content;
  padding: 33px 0;
  line-height: 1;
  &.tablet {
    padding: 28px 0;
  }
  &.mobile {
    padding: 16px 0;
  }
}
.header-actions {
  margin-left: auto;
  text-align: right;
}
