@import '/app/src/theme/variables.module.less';

@primary-blue-8: #0068d7;
@primary-blue-7: #1f7de0;
@primary-blue-6: #2487e3;
@blue-850: #184460;
@blue-9: #032f4a;
@main-blue-gray-8: #4d7187;
@cta-2: #d32e1d;
@cta-hover-bg: #f74431;
@cta-active-bg: #ff7875;
@main-blue-gray-1: #f9fafb;
@main-blue-gray-3: #e5eaed;
@main-orange-8: #f57e2e;
@main-orange-10: #f85a00;
@main-gray-5: #b8bdc0;
@main-gray-4: #d0d3d6;
@blue-7: #829bab;
@blue-6: #aabbc6;
@primary-blue-2: #e8f3ff;
@primary-blue-3: #c4e1ff;
@white: #ffffff;
@navigation-on-dark-hover: rgba(240, 240, 240, 0.2);
@font-second: "Montserrat", "Arial", sans-serif;
@btn-primary-color: @white;
@btn-border-radius-base: 2px;
@btn-font-weight: 600;
@btn-font-size-lg: 16px;
@btn-font-size-sm: 12px;
@btn-line-height: 22px;
@btn-default-color: @black;
@btn-danger-bg: @cta-2;
@btn-danger-border: @cta-2;
@btn-disable-color: @white;
@btn-disable-bg: @main-gray-4;
@btn-disable-border: @main-gray-4;
:global {
  .ant-btn {
    font-weight: @btn-font-weight;
    line-height: @btn-line-height;
    color: @btn-default-color;
    border-radius: @btn-border-radius-base;
  }
  .ant-btn-link {
    color: @primary-blue-8;
    &:hover,
    &:focus {
      color: @primary-blue-6;
    }
  }
  .ant-btn-dangerous.ant-btn-primary {
    background: @btn-danger-bg;
    border-color: @btn-danger-border;
    &:hover {
      background-color: @cta-hover-bg;
    }
    &:focus {
      background: @btn-danger-bg;
      border-color: @btn-danger-border;
    }
    &:active {
      background: @cta-active-bg;
      border-color: @cta-active-bg;
    }
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      color: @white;
      background-color: @main-gray-4;
      border: 0;
    }
  }
  .ant-btn-lg {
    padding: 8px 15px;
    font-size: @btn-font-size-lg;
  }
  .ant-btn-sm {
    font-size: @btn-font-size-sm;
  }
  .ant-btn-text {
    color: @primary-blue-8;
    &:hover {
      color: @primary-blue-8;
    }
    &:active {
      color: @primary-blue-8;
    }
  }
  .ant-btn-primary {
    color: @btn-primary-color;
    background: @primary-blue-8;
    border-color: @primary-blue-8;
    border-radius: 2px;
    &:hover,
    &:focus {
      background: @primary-blue-7;
      border-color: @primary-blue-7;
    }
  }
  .ant-btn-background-ghost.ant-btn-primary {
    color: @primary-blue-8;
    border-color: @primary-blue-8;
    &:hover,
    &:focus {
      color: @primary-blue-6;
      border-color: @primary-blue-6;
    }
  }
  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    color: @white;
    background: @main-gray-4;
    border-color: @main-gray-4;
  }
  a.ant-btn.ant-btn-link {
    line-height: 22px;
  }
  a.ant-btn {
    padding: 4px 15px !important;
  }
  a.ant-btn-lg {
    padding: 8px 15px !important;
  }
  a.ant-btn-sm {
    padding: 0 7px !important;
  }
}
.btn {
  height: auto;
  min-height: 24px;
  font-family: @font-second;
  white-space: pre-line;
  transition: height 0s;
  :global {
    .anticon {
      + span {
        vertical-align: initial;
      }
      .anticon {
        line-height: 0;
      }
    }
  }
  &.navigation-on-dark {
    color: @white;
    background-color: transparent;
    border-color: @blue-7;
    border-radius: 2px;
    &:hover,
    &:focus {
      color: @white;
      background-color: @navigation-on-dark-hover;
      border-color: @blue-7;
    }
    &:disabled {
      background-color: @main-gray-4;
    }
  }
  &.become {
    background-color: @blue-9;
    border-color: @blue-9;
    &:hover {
      background-color: @primary-blue-8;
      border-color: @primary-blue-8;
    }
    &:active {
      background-color: @primary-blue-9;
      border-color: @primary-blue-9;
    }
  }
  &.show-more {
    font-weight: 400;
    line-height: 16px;
    color: @main-blue-gray-8;
    transition: color ease 0.2s;
    &:hover {
      color: @blue-850;
    }
  }
  &.add-to-cart {
    background-color: @cta-2;
  }
  &.delete-photo {
    color: @cta-2;
    transition: 0.15s ease-in-out opacity;
    &:hover {
      opacity: 0.8;
    }
  }
  &.extra-large {
    height: 56px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  &.no-style {
    width: auto;
    min-height: auto;
    padding: 0;
    margin: 0;
    border: none;
  }
  &.download-link {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 4px 8px;
    font-size: 12px;
    line-height: 16px;
    background: @main-blue-gray-1;
    border-radius: 2px;
  }
  &.downlaod-invoice {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 9px 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    background: @main-blue-gray-1;
    border-radius: 2px;
  }
  &.size-btn {
    padding: 4px 8px;
    font-size: 12px;
    line-height: 16px;
    color: @main-gray-5;
    background-color: @main-blue-gray-1;
    border-radius: 2px;
  }
  &.reset-filter {
    padding: 0 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: @btn-font-size-sm;
    font-weight: 600;
    line-height: @btn-line-height;
    color: @main-orange-8;
    vertical-align: middle;
    :global {
      .anticon {
        font-size: 10px;
        + span {
          vertical-align: initial;
        }
      }
    }
    &:hover {
      color: @main-orange-8;
      border-color: @main-orange-8;
    }
  }
  &.intro-navs {
    padding: 2px 3px;
    color: @white;
    background-color: @main-orange-10;
    border-color: @main-orange-10;
    &:hover {
      background-color: @main-orange-8;
      border-color: @main-orange-8;
    }
    &:active {
      background-color: @main-orange-8;
      border-color: @main-orange-8;
    }
  }
  &.article-link {
    text-align: left;
  }
  &.read-more-link {
    font-weight: 400;
    text-align: left;
  }
  &.search-bar {
    padding-right: 8px;
    padding-left: 8px;
  }
  &.download-pdf {
    color: @primary-blue-8;
    background: @main-blue-gray-1;
    border: none;
    &:disabled {
      color: @white;
      background: @main-gray-4;
      border-color: @main-gray-4;
    }
  }
  &.paddingless {
    padding-right: 0;
    padding-left: 0;
  }
  &.customer-card {
    color: @blue-7;
    &:hover {
      color: @blue-6;
    }
    &:not(:last-child) {
      > :global(.anticon) {
        border-right: 1px solid @main-blue-gray-3;
      }
    }
  }
  &.customer-vehicle {
    border: 0;
    box-shadow: none;
  }
  &.customer-vehicle-action,
  &.customer-vehicle-action-del {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    border-radius: 2px;
  }
  &.customer-vehicle-action:not([disabled]),
  &.customer-vehicle-action-del:not([disabled]) {
    border: 1px solid @primary-blue-8;
    &-del {
      color: @primary-blue-8;
    }
    &-del:hover {
      color: @primary-blue-6;
      border-color: @primary-blue-6;
    }
  }
  &.customer-vehicle-action:not([disabled]):hover {
    border-color: @primary-blue-6;
  }
  &.icon {
    color: @main-gray-5;
    background-color: unset;
    border-color: transparent;
    &:hover,
    &:focus {
      color: @primary-blue-8;
      background-color: @primary-blue-2;
      border-color: @primary-blue-2;
    }
    &:active,
    &-active {
      color: @primary-blue-8;
      background-color: @primary-blue-3;
      border-color: @primary-blue-3;
    }
  }
}
.tooltip {
  z-index: 40;
  max-width: 500px;
}
