@import '/app/src/theme/variables.module.less';

.label {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 2px;
  span {
    &::first-letter {
      text-transform: uppercase;
    }
  }
}
