@import '/app/src/theme/variables.module.less';

.decor-divider {
  margin: 0;
  &.gutter-empty {
    margin-bottom: 0;
  }
  &.gutter-small {
    margin-bottom: 8px;
  }
  &.gutter-middle {
    margin-bottom: 16px;
  }
  &.gutter-large {
    margin-bottom: 24px;
  }
  &.gutter-middle-no-border {
    margin-bottom: 16px;
    border: none;
  }
  &.cell {
    height: 24px;
  }
  &.main-default {
    color: inherit;
  }
  &.main-gray-9 {
    border-color: #132530;
  }
}
