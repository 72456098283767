@import '/app/src/theme/variables.module.less';

@modal-default-width: 438px;
@modal-auto-width: auto;
@modal-search-width: 848px;
@modal-cookie-width: 848px;
@modal-track-width: 632px;
@modal-vehicle-width: 848px;
@modal-header-close-size: 20px;
@modal-content-bg: @blue-1;
@modal-close-color: @neutral-5;
@modal-mask-bg: rgba(@neutral-9, 0.92);
@gray-1: #fafbfb;
:global {
  .ant-modal-mask {
    background-color: @modal-mask-bg;
  }
  .ant-modal-close {
    top: -36px;
    color: @modal-close-color;
    &:hover {
      color: @gray-1;
    }
  }
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    font-size: @modal-header-close-size;
  }
  .ant-modal-content {
    background-color: @modal-content-bg;
    border-radius: 6px;
  }
  .survey-wrapper {
    .ant-modal-content {
      background-color: transparent;
      border-radius: 0;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
}
[class~="modal-wrap-dark-mask"] {
  z-index: 1035;
  background: rgba(4 24 36 / 85%);
}
.modal-gutter {
  &-none {
    :global {
      .ant-modal-body {
        padding: 0;
      }
    }
  }
  &-small {
    :global {
      .ant-modal-body {
        padding: 16px;
      }
    }
  }
  &-middle {
    :global {
      .ant-modal-body {
        padding: 24px;
      }
    }
  }
  &-large {
    :global {
      .ant-modal-body {
        padding: 32px;
      }
    }
  }
}

/* stylelint-disable selector-pseudo-class-no-unknown */
/* stylelint-disable property-no-unknown */
:export {
  modalDefaultWidth: @modal-default-width;
  modalSearchWidth: @modal-search-width;
  modalCookiesWidth: @modal-cookie-width;
  modalTrackWidth: @modal-track-width;
  modalVehicleWidth: @modal-vehicle-width;
  modalAutoWidth: @modal-auto-width;
}
