@import '/app/src/theme/variables.module.less';

.empty {
  height: 100%;
  margin: 0;
  :global {
    .ant-empty,
    .ant-empty .ant-empty-image,
    .ant-empty-normal .ant-empty-image {
      margin: 0;
    }
  }
}
