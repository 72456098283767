@import '/app/src/theme/variables.module.less';

@primary-blue-8: #0068d7;
@main-orange-8: #f57e2e;
:global {
  .ant-spin-spinning {
    color: inherit;
  }
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  &-spinning {
    :global {
      .ant-spin-spinning {
        animation: loadingCircle 1s infinite linear;
      }
    }
  }
  &-size {
    font-size: inherit;
    &-extra-large {
      :global {
        .ant-spin-dot {
          font-size: 48px;
        }
      }
    }
    &-large {
      font-size: inherit;
      :global {
        .ant-spin-dot {
          font-size: 32px;
        }
      }
    }
    &-medium {
      font-size: inherit;
      :global {
        .ant-spin-dot {
          font-size: 24px;
        }
      }
    }
    &-small {
      font-size: inherit;
      :global {
        .ant-spin-dot {
          font-size: 18px;
        }
      }
    }
    &-tiny {
      font-size: inherit;
      :global {
        .ant-spin-dot {
          font-size: 12px;
        }
      }
    }
  }
  &-color {
    &-blue-8 {
      color: @primary-blue-8;
    }
    &-orange-8 {
      color: @main-orange-8;
    }
  }
}
