@import '/app/src/theme/variables.module.less';

@containerMinWidth: 1328px;
.layout {
  position: relative;

  /*
    Correct for stretching layout:
    @params height: 100vh;
    @params min-height: 100%;
    ---
    Incorrect for stretching layout:
    @params height: auto;
    @params min-height: 100vh;
  */
  height: 100vh;
  min-height: 100%;
  &.private {
    min-width: @containerMinWidth;
  }
  &.public {
    min-width: auto;
  }
}
