@import url("./palette.less");
*,
*::after,
*::before {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
}
body,
html {
  padding: 0;
  margin: 0;
  font-family: @font-family;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* stylelint-disable */
  font-smoothing: antialiased;
  /* stylelint-enable */
  font-variant-ligatures: none;
  line-height: 1.5;
  scroll-behavior: smooth;
}
section {
  width: 100%;
}
img {
  max-width: 100%;
  height: auto;
}
