@import '/app/src/theme/variables.module.less';

.footer-menu {
  height: inherit;
}
.footer-last {
  &.mobile {
    margin-top: 32px;
  }
}
.footer-menu-title {
  margin-bottom: 16px;
}
