@import '/app/src/theme/variables.module.less';

@containerMaxWidth: 1328px;
@layout-fix-min-width: 376px;
@containerPadding: 24px;
@containerPaddingMobile: 12px;
.container {
  z-index: 1;
  width: 100%;
  min-width: @layout-fix-min-width;
  max-width: @containerMaxWidth;
  height: 100%;
  padding: 0 @containerPadding;
  margin: 0 auto;
  &.full {
    max-width: none;
  }
  &.tablet {
    padding: 0 @containerPaddingMobile;
  }
  &.mobile {
    padding: 0 @containerPaddingMobile;
  }
}
