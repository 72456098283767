@import '/app/src/theme/variables.module.less';

:global {
  .ant-popover-inner-content {
    padding: 0;
  }
}
.popover {
  display: flex;
}
