[role="to-be-done"],
.ant-menu-item[data-menu-id*="returns-warranty"],
.ant-menu-item[data-menu-id*="repair-estimates"],
[data-menu-id*="/help-centre"],
[data-menu-id*="/withdrawal"],
[data-menu-id*="/returns-refunds"],
[class^="lastDocuments"] .ant-tabs-tab:nth-child(2),
[class^="lastDocuments"] .ant-tabs-tab:nth-child(4) {
  display: none !important;
}
