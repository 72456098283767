@import '/app/src/theme/variables.module.less';

@success-color: #52c41a;
@error-color: #f64f00;
@text-color-secondary: rgba(0, 0, 0, 0.7);
@gray-8: #444f54;
.notification {
  padding: 13.5px 24px;
  @media (width >= 768px) and (width <= 1200px) {
    width: 348px;
  }
  @media (width <= 767px) {
    width: 100%;
  }
  :global {
    .ant-notification-notice-content {
      min-height: 82px;
    }
    .ant-notification-notice-icon {
      margin: 0;
    }
    .ant-notification-notice-with-icon .ant-notification-notice-message {
      display: block;
      padding-right: 0;
      padding-left: 96px;
      margin: 0;
      margin-bottom: 8px;
    }
    .ant-notification-notice-with-icon .ant-notification-notice-description {
      padding-left: 96px;
      margin: 0;
    }
  }
}
.title {
  font-size: 24px;
  line-height: 32px;
}
.desc {
  line-height: 22px;
  color: @gray-8;
}
.success {
  color: @success-color;
}
.error {
  color: @error-color;
}
.info {
  color: @text-color-secondary;
}
