@import '/app/src/theme/variables.module.less';

.block {
  position: relative;
}
.block-alignment-horizontal {
  &-left {
    text-align: left;
  }
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
}
