@import '/app/src/theme/variables.module.less';

@font-base: "Inter", "Arial", sans-serif;
@font-second: "Montserrat", "Arial", sans-serif;
@font-family: -apple-system, BlinkMacSystemFont, @font-base, @font-second, Roboto, "Helvetica Neue",
  Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji";
@white-100: #ffffff;
@gray-300: #e5eaed;
@gray-400: #d0d3d6;
@gray-700: #59666e;
@gray-900: #132530;
@gray-950: #041824;
@blue-600: #4594e9;
@blue-700: #1f7de0;
@blue-800: #0068d7;
@blue-900: #0058b5;
@blue-1000: #004997;
@blue-gray-600: #aabbc6;
@blue-gray-700: #829bab;
@blue-gray-800: #4d7187;
@blue-gray-850: #184460;
@blue-gray-900: #032f4a;
@main-orange-8: #f57e2e;
@main-orange-10: #f85a00;
.link {
  font-weight: inherit;
  color: inherit;
  text-decoration: none;
  text-wrap: balance;
  word-break: break-all;
  touch-action: manipulation;
  cursor: pointer;
  outline: 0;
  transition: color 0.3s;
}
.link-icon {
  display: flex;
  align-items: center;
}
.link-type {
  &-disabled {
    color: #00000040;
    word-break: break-word;
    cursor: not-allowed;
    user-select: none;
  }
  &-default {
    color: @blue-gray-600;
    &:hover {
      color: @gray-300;
    }
    &:focus {
      color: @blue-gray-600;
    }
    &:active {
      color: @blue-gray-700;
    }
  }
  &-primary {
    color: @blue-800;
    &:hover {
      color: @blue-700;
    }
    &:focus {
      color: @blue-700;
    }
    &:active {
      color: @blue-900;
    }
  }
  &-secondary {
    color: @blue-gray-900;
    &:hover {
      color: @blue-800;
    }
    &:focus {
      color: @blue-800;
    }
    &:active {
      color: @blue-800;
    }
  }
  &-link {
    color: @white-100;
    background-color: @blue-800;
    &:hover {
      color: @white-100;
      background-color: @blue-700;
    }
    &:focus {
      color: @white-100;
      background-color: @blue-700;
    }
    &:active {
      color: @white-100;
      background-color: @blue-900;
    }
  }
  &-banner {
    color: @white-100;
    &:hover {
      color: @white-100;
    }
    &:focus {
      color: @white-100;
    }
    &:active {
      color: @white-100;
    }
  }
}
.link-color-white {
  &-100 {
    color: @white-100;
    &:hover {
      color: @white-100;
    }
    &:active {
      color: @white-100;
    }
    &:focus {
      color: @white-100;
    }
  }
}
.link-color-blue {
  &-800 {
    color: @blue-800;
    &:hover,
    &:focus,
    &:active {
      color: @blue-600;
    }
  }
}
.link-border-color-blue {
  &-800 {
    border: 1px solid @blue-800;
  }
}
.link-bg-color-orange {
  &-8 {
    background-color: @main-orange-8;
    &:hover {
      background-color: @main-orange-10;
    }
    &:active {
      background-color: @main-orange-10;
    }
    &:focus {
      background-color: @main-orange-10;
    }
  }
  &-10 {
    background-color: @main-orange-10;
    &:hover {
      background-color: @main-orange-8;
    }
    &:active {
      background-color: @main-orange-8;
    }
    &:focus {
      background-color: @main-orange-8;
    }
  }
}
.link-bg-color-blue {
  &-800 {
    background-color: @blue-800;
    &:hover,
    &:focus,
    &:active {
      background-color: @blue-600;
    }
  }
}
.link-bg-color-gray {
  &-400 {
    background-color: @gray-400;
  }
}
.link-box {
  &-inline {
    display: inline-flex;
  }
  &-block {
    display: inline-flex;
    width: 100%;
  }
}
.link-text-value {
  display: flex;
  align-items: center;
}
.link-no-styles {
  display: block;
  text-wrap: unset;
  word-break: unset;
  cursor: unset;
  &:hover,
  &:active,
  &:visited {
    color: unset;
  }
  .link-text-value {
    display: block;
    align-items: unset;
  }
}
.link-disabled {
  pointer-events: none;
}
.link-alignment-vertical {
  &-top {
    vertical-align: top;
  }
  &-middle {
    vertical-align: middle;
  }
  &-bottom {
    vertical-align: bottom;
  }
}
.link-alignment-horizontal {
  &-left {
    justify-content: flex-start;
  }
  &-center {
    justify-content: center;
  }
  &-right {
    justify-content: flex-end;
  }
}
.link-padding {
  &-middle {
    padding: 6px 16px;
    border-radius: 2px;
  }
}
.link-decoration {
  &-none {
    text-decoration: none;
  }
  &-underline {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
}
.link-size {
  &-12 {
    font-size: 12px;
    line-height: 16px;
  }
  &-14 {
    font-size: 14px;
    line-height: 22px;
  }
  &-16 {
    font-size: 16px;
    line-height: 24px;
  }
  &-20 {
    font-size: 20px;
    line-height: 28px;
  }
  &-24 {
    font-size: 24px;
    line-height: 32px;
  }
}
.link-font {
  &-system {
    font-family: @font-family;
  }
  &-base {
    font-family: @font-base;
  }
  &-second {
    font-family: @font-second;
  }
}
.link-font-weight {
  &-300 {
    font-weight: 300;
  }
  &-400 {
    font-weight: 400;
  }
  &-600 {
    font-weight: 600;
  }
  &-700 {
    font-weight: 700;
  }
}
.link-text-icon {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
