@import '/app/src/theme/variables.module.less';

.tec-doc-logo {
  color: inherit;
  &-copyright {
    color: inherit;
  }
  &-image {
    color: inherit;
  }
  &-container {
    margin-bottom: 16px;
  }
}
