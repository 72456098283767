@import '/app/src/theme/variables.module.less';

.popover-user {
  min-width: 416px;
  padding: 12px 16px;
  .user-greeting {
    display: flex;
    flex-direction: column;
  }
}
