@import '/app/src/theme/variables.module.less';

.form-actions {
  width: 100%;
}
.center {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  height: 100%;
}
.form {
  padding-top: 24px;
}
.input {
  padding-top: 24px;
}
.wrapper {
  padding: 24px 0;
}
.form-actions-alignment-horizontal {
  &-left {
    text-align: left;
  }
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
}
