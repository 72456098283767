@import '/app/src/theme/variables.module.less';

@bannerGradient-1: linear-gradient(180deg, #ff471a 0%, #ff6700 100%);
.banner {
  position: relative;
  z-index: 10;
  line-height: 1;
  background: @bannerGradient-1;
}
.center {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
}
.content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: right;
  text-transform: uppercase;
}
.text {
  position: relative;
  margin-right: 14px;
  ::after {
    position: absolute;
    top: 50%;
    right: -8px;
    display: block;
    width: 3px;
    height: 3px;
    margin-top: -1px;
    margin-left: 4px;
    content: "";
    background: @white;
    border-radius: 50%;
  }
}
.timer {
  line-height: 1;
}
