@import '/app/src/theme/variables.module.less';

@font-base: "Inter", "Arial", sans-serif;
@font-size-sm: 12px;
@states-warning: #ed5c0e;
@gray-5: #b8bdc0;
@gray-7: #5b6569;
@states-error: #f64f00;
@blue-6: #aabbc6;
@blue-9: #032f4a;
@neutral-6: #8e979c;
:global {
  .ant-input {
    &::placeholder {
      color: @gray-7;
    }
  }
  .ant-form-item-label {
    line-height: 1;
  }
  .ant-form-item-label > label {
    width: 100%;
    height: auto;
  }
  .ant-form-item-label,
  .ant-form-vertical .ant-form-item-label {
    padding: 0;
  }
  .ant-form-item-has-error :not(.ant-input-disabled, .ant-input-borderless).ant-input {
    border-color: @states-warning;
  }
  .ant-form-item-explain {
    width: 100%;
    font-family: @font-base;
    font-size: @font-size-sm;
    font-weight: 400;
    line-height: 22px;
    color: @states-error;
    text-align: left;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  .ant-form-item-control-input {
    min-height: auto;
  }
}
.item {
  position: relative;
  line-height: 1;
}
.item-type {
  &-base {
    color: inherit;
  }
  &-no-gutter {
    margin: 0;
  }
  &-no-last {
    margin-bottom: 24px;
  }
  &-small {
    margin-bottom: 8px;
  }
  &-middle {
    margin-bottom: 16px;
  }
  &-search-bar {
    margin: 0;
    :global {
      input.ant-input {
        font-size: 14px;
        color: @blue-9;
      }
      .ant-input-suffix {
        font-size: 12px;
        line-height: 14px;
        color: @neutral-6;
      }
      .ant-input-affix-wrapper-lg {
        padding: 8px 11px;
      }
      .ant-select {
        cursor: default;
      }
    }
  }
  &-header {
    margin: 0;
    :global {
      .ant-form-item-label {
        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          color: @blue-7;
          text-transform: uppercase;
        }
      }
    }
  }
  &-footer {
    margin: 0;
    :global {
      .ant-form-item-label {
        margin-bottom: 8px;
        span {
          color: @blue-6;
        }
      }
    }
  }
}
.skeleton {
  width: 100%;
  border-radius: 4px;
  &-phone-number {
    padding-bottom: 24px;
    :global .ant-skeleton-input {
      width: 100%;
    }
  }
  :global .ant-skeleton-input {
    width: 100%;
    min-width: auto;
  }
  &-counter:global.ant-skeleton-input {
    width: 90px;
    min-width: 90px;
  }
  &:not(:last-child) {
    margin-bottom: 14px;
  }
}
