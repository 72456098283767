@import '/app/src/theme/variables.module.less';

@main-orange-8: #f57e2e;
.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: rgb(@main-orange-8 0.1);
  border-radius: 50%;
}
.inner {
  width: 12px;
  height: 12px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: @main-orange-8;
  border-radius: 50%;
}
