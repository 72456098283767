@import '/app/src/theme/variables.module.less';

@blue-2: #f1f4f6;
.descriptions {
  color: inherit;
}
.descriptions-item {
  color: inherit;
}
.descriptions-skeleton {
  width: 50%;
  height: 16px;
  :global .ant-skeleton-input {
    height: 16px;
  }
}
.static {
  :global {
    .ant-descriptions-item {
      padding: 16px 24px;
      border: 1px solid @blue-2;
      &-label {
        width: 50%;
        font-size: 14px;
        line-height: 16px;
      }
      &-content {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
      }
    }
    .ant-descriptions-row:first-child {
      background-color: @blue-2;
    }
  }
}
.dynamic {
  :global {
    .ant-descriptions-item {
      padding: 12px 0;
      border-bottom: 1px solid @blue-2;
      &-label {
        width: 50%;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        color: @neutral-9;
      }
      &-content {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
.vertical {
  :global {
    .ant-descriptions-item {
      &-label {
        width: 100%;
      }
    }
  }
}
.notice {
  :global {
    .ant-descriptions-item {
      padding: 12px 0;
      border-bottom: 1px solid @blue-2;
      &-label {
        width: 50%;
        &::after {
          content: "";
        }
      }
      &-content {
        font-weight: 600;
      }
    }
    .ant-descriptions-row:first-child {
      text-transform: uppercase;
      background-color: @blue-2;
      .ant-descriptions-item-label {
        justify-content: flex-end;
        font-weight: 600;
      }
    }
  }
}
.skeleton {
  width: 100%;
  height: 16px;
  margin: 12px 0;
  :global .ant-skeleton-input {
    width: 100%;
    height: 16px;
  }
}
