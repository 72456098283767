@import '/app/src/theme/variables.module.less';

.logo {
  display: inline-block;
  flex: 1;
  max-width: 200px;
  max-height: 40px;
  text-align: center;
  vertical-align: middle;
  &.tablet {
    max-width: 176px;
    max-height: 36px;
  }
  &.mobile {
    max-width: 80px;
    max-height: 24px;
  }
}
.box {
  display: inline-block;
  font-size: 0;
  line-height: 1;
  vertical-align: top;
}
