@import '/app/src/theme/variables.module.less';

@blue-1000: #014389;
@white-100: #ffffff;
@orange-900: #f85a00;
.survey {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 848px;
  height: 436px;
  padding-top: 36px;
  overflow: hidden;
  background-color: @blue-1000;
  background-image: url("/assets/images/survey-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  border-radius: 12px;
  &-content {
    padding-top: 12px;
    padding-left: 60px;
  }
  &-text {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 32px;
  }
  &-link {
    display: inline-flex;
    padding: 9px 16px;
    background: @white-100;
    border-radius: 2px;
  }
}
.search-notice {
  &-subtitle {
    margin-bottom: 16px;
    text-align: center;
  }
  &-content {
    padding: 16px;
    margin-bottom: 16px;
    background: @white;
    border: 1px solid @main-blue-gray-3;
    border-radius: 6px;
  }
}
.order-tracking {
  &-table {
    margin-top: 12px;
  }
}
.description-items {
  margin-bottom: 16px;
}
.fit-button {
  min-width: fit-content;
}
.modal-signin-lead-registered {
  .fit-button {
    margin-bottom: 16px;
  }
}
.modal-error {
  padding: 32px;
  .description-items {
    display: block;
    margin: -16px 0 0;
  }
}
.modal-signin-lead-registered,
.modal-callback-limit,
.modal-callback-personal-limit {
  height: inherit;
  .description-items {
    display: block;
    margin: -16px 0 0;
  }
}
.action-btns-block,
.action-btn {
  display: flex;
  button {
    height: 40px;
  }
}
.action-btns-block {
  gap: 16px;
  margin: 8px 0 0;
}
.action-btn {
  margin: 24px 0 0;
}
.tiny-inline-skeleton {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  height: 10px;
}
.fit-width {
  min-width: 100%;
  margin-bottom: 5px;
}
.customer-edit {
  .car-param-title {
    padding: 0;
    font-size: 14px;
    line-height: 22px;
    color: @main-gray-6;
    vertical-align: middle;
    box-shadow: none;
  }
  .car-param-content {
    padding: 0;
    font-size: 14px;
    line-height: 22px;
    color: @main-gray-9;
    vertical-align: middle;
    box-shadow: none;
  }
  .car-param-image-block {
    width: 100%;
    height: 144px;
  }
  .car-photo {
    display: flex;
    justify-content: flex-end;
  }
  .car-no-editable {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    height: 100%;
  }
}
.modal-saved-basket-create {
  width: 100%;
}
.modal-vehicle-tabs-remove-available {
  min-height: 460px;
}
.modal-signup-success,
.modal-signup-lead-success,
.modal-signup,
.modal-signin-error,
.customer-edit,
.modal-notice,
.modal-signup-no-vat,
.modal-callback,
.modal-personal-manager-callback,
.modal-signout-confirm,
.modal-customer-create,
.modal-customer-delete-confirm,
.modal-customer-vehicle-remove,
.modal-cart-vehicle-delete,
.modal-repair-estimate-delete-confirm,
.modal-repair-estimate-status-confirm,
.modal-repair-estimate-checkout-confirm,
.modal-reset-pwd,
.modal-return-confirm,
.modal-saved-basket-delete,
.modal-saved-basket-checkout-confirm,
.modal-select-vehicle,
.modal-search-notice,
.modal-service-timeout,
.modal-orders-checkout-service-timeout,
.modal-orders-checkout-company-invalid {
  height: inherit;
}
