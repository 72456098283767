@import '/app/src/theme/variables.module.less';

@font-base: "Inter", "Arial", sans-serif;
@gray-6: #8e979c;
@gray-9: #132530;
@main-gray-6: #aabbc6;
@main-gray-8: #2a3a44;
:global {
  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    color: @gray-6;
    background: @gray-9;
  }
  .ant-menu.ant-menu-dark {
    border-radius: 8px;
  }
  .ant-menu-sub.ant-menu-vertical {
    padding: 8px;
    border-radius: 4px;
    .ant-menu-item.ant-menu-item-only-child {
      margin: 0;
      font-weight: 600;
      border-bottom: 1px solid @main-gray-8;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
    padding-right: 18px;
  }
  .ant-menu-horizontal > .ant-menu-item a,
  .ant-menu-dark .ant-menu-item > span > a {
    font-family: @font-base;
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
    color: @main-gray-6;
  }
  .ant-menu-title-content {
    display: flex;
  }
  .ant-menu-vertical .ant-menu-title-content {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .ant-menu-item.ant-menu-item-selected,
  .ant-menu-item:active {
    background: transparent;
  }
  .ant-menu-item:focus-visible {
    box-shadow: none;
  }
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    display: flex;
    align-items: center;
    padding: 6px 20px 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 38px;
    color: @main-gray-6;
    background: @gray-9;
  }
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: transparent;
  }
  .ant-menu-dark.ant-menu-horizontal {
    display: flex;
    justify-content: center;
  }
}
.menu {
  height: inherit;
  :global {
    .ant-menu-submenu-arrow {
      right: 0;
      display: inline-block;
      transform: rotate(90deg);
    }
    .ant-menu-submenu-open .ant-menu-submenu-arrow {
      transform: rotate(-90deg);
    }
    .ant-menu-item::after,
    .ant-menu-submenu::after {
      display: none;
    }
  }
}
