@import '/app/src/theme/variables.module.less';

.header {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 0;
  background: @neutral-9;
}
.header-top {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  width: 100%;
  height: 104px;
  padding: 28px 0;
  line-height: 1;
}
.header-bottom {
  border-top: 2px solid @main-gray-8;
}
.actions {
  text-align: right;
}
