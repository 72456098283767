@import '/app/src/theme/variables.module.less';

@bannerGradient-1: linear-gradient(180deg, #ff471a 0%, #ff6700 100%);
@white-100: #ffffff;
.banner {
  position: relative;
  z-index: 10;
  line-height: 1;
  background: @bannerGradient-1;
}
.center {
  text-align: center;
}
