@import '/app/src/theme/variables.module.less';

.badge {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: unset;
  :global {
    .ant-badge-count {
      top: 4px;
      box-shadow: none;
    }
    .ant-scroll-number-custom-component {
      top: 4px;
      right: 4px;
    }
  }
}
