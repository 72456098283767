@import '/app/src/theme/variables.module.less';

.popover-notification {
  width: 416px;
  height: auto;
  max-height: 240px;
  padding: 16px;
  overflow-y: auto;
  &-item {
    display: flex;
    align-items: flex-start;
    width: 380px;
    padding: 16px 0;
  }
  &-indicator {
    margin-right: 16px;
  }
  &-time {
    margin-bottom: 16px;
  }
  &-action-container {
    display: flex;
    justify-content: center;
  }
}
.skeleton-content {
  &:global.ant-skeleton {
    width: 100%;
    height: 42px;
    margin-bottom: 8px;
    .ant-skeleton-input {
      width: 100%;
      height: 42px;
    }
  }
  &-time {
    &:global.ant-skeleton {
      width: 100%;
      height: 16px;
      .ant-skeleton-input {
        width: 100%;
        height: 16px;
      }
    }
  }
}
