@import '/app/src/theme/variables.module.less';

.error-fallback {
  height: 100%;
  min-height: 80vh;
  text-align: center;
}
.error-message {
  margin-bottom: 16px;
}
.illustration {
  width: 100%;
  min-height: 400px;
  margin-bottom: 16px;
}
.description {
  margin: 20px 0;
}
